<template>
  <div class>
    <Navigation />

    <div class="wrap-box">
      <h3 class="heading">Новини закладу</h3>
    </div>

    <div class="wrap-box">
      <div v-if="news.length" class="news-container">
        <router-link
          v-for="(newsItem, index) in news"
          :key="index"
          :to="'/news/' + newsItem.slug"
          class="news-item"
        >
          <div class="news-item-image" :style="`background-image: url('${newsItem.image}')`"></div>
          <span class="news-item-title">{{newsItem.title}}</span>
          <div class="news-item-date">{{showDate(newsItem.date)}}</div>
        </router-link>
      </div>
      <p v-else>Тут поки що немає світлин</p>
    </div>
  </div>
</template>
<script>
import Navigation from "../components/Navigation";

export default {
  name: "News",
  components: {
    Navigation
  },
  data: () => ({
    isLoadingContent: false,
    nomoreNewsLeft: false,
    news: [
      // {
      //   title: "Вітаємо з Днем Конституції України!",
      //   photo: "https://dsp.gov.ua/wp-content/uploads/2020/06/konst-1.jpg",
      //   slug: "https://google.com",
      //   time: "19.06.2021"
      // },
      // {
      //   title: "Відкрито новий відділ » Дитяче здоров’я»!",
      //   photo:
      //     "https://rada.info/upload/users_files/38960518/76be7f75c2333c7bd287a5557e76573b.jpeg",
      //   slug: "https://google.com",
      //   time: "19.06.2021"
      // },
      // {
      //   title:
      //     "Як відбувається тестування швидкими тестами на антиген до SARS-CoV-2 (коронавірусу).",
      //   photo:
      //     "https://verde.com.ua/upload/news/2021/02/16/16602bf51d9803f-602bf4b553aca-foto-k-state-pro-byzyakynu_crop_415x165.jpg?v=1613493537",
      //   slug: "https://google.com",
      //   time: "19.06.2021"
      // },
      // {
      //   title: "Графіки роботи лікарів та телефони.",
      //   photo:
      //     "https://promin.cv.ua/uploads/posts/2019-07/thumbs/1562679383_60407463_2039038419735199_4113948219908554752_n.jpg",
      //   slug: "https://google.com",
      //   time: "19.06.2021"
      // },
      // {
      //   title:
      //     "Вакцинація за допомогою мобільних бригад з імунізації від Covid-19",
      //   photo: "https://img.pravda.com/images/doc/8/3/83438e6-vaccine-c.jpg",
      //   slug: "https://google.com",
      //   time: "19.06.2021"
      // },
      // {
      //   title: "Вакцинація проти дифтерії та правця",
      //   photo:
      //     "https://static.wixstatic.com/media/168de4_15619294649b47538ffeb58b2abf72e6~mv2.jpg/v1/fill/w_545,h_362,fp_0.50_0.50,lg_1,q_90/168de4_15619294649b47538ffeb58b2abf72e6~mv2.webp",
      //   slug: "https://google.com",
      //   time: "19.01.2021"
      // },
      // {
      //   title:
      //     "З Новим роком та Різдвом Христовим!",
      //   photo: "https://static.wixstatic.com/media/168de4_6e542bfbe2754646905a0ccb4090d643~mv2.jpg/v1/fill/w_568,h_426,fp_0.50_0.50,q_90/168de4_6e542bfbe2754646905a0ccb4090d643~mv2.webp",
      //   slug: "https://google.com",
      //   time: "06.01.2021"
      // }
    ]
  }),
  async created() {
    await this.loadNews(6);
    window.addEventListener("scroll", this.handleScroll);
  },
  destroyed() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  methods: {
    showDate(string) {
      if (string == "") return;
      let formatter = new Intl.DateTimeFormat("ru", {
        year: "numeric",
        month: "numeric",
        day: "numeric"
      });

      const date = new Date(string);
      return formatter.format(date);
    },
    async handleScroll() {
      if (this.nomoreNewsLeft) return;
      if (this.isLoadingContent) return;
      let clientWidth = document.documentElement.clientWidth;
      let pageYOffset = window.pageYOffset; // height of the current scroll
      let clientHeight = document.documentElement.clientHeight; // height of the window
      let scrollHeight = document.documentElement.scrollHeight; // height of the page

      // console.log(scrollHeight - clientHeight < pageYOffset * 1.25);
      // console.log(clientHeight, scrollHeight, pageYOffset);

      let needsToLoadMore;
      if (clientWidth < 600) {
        needsToLoadMore = scrollHeight - pageYOffset < clientHeight * 1.75;
      } else {
        needsToLoadMore = scrollHeight - pageYOffset < clientHeight * 1.2;
      }
      if (needsToLoadMore) {
        this.isLoadingContent = true;
        await this.loadNews(3);
        setTimeout(() => {
          this.isLoadingContent = false;
        }, 100);
      }
    },
    async loadNews(newsNumber) {
      const currentPage = Math.ceil(this.news.length / newsNumber);
      const nextPage = currentPage + 1;
      const json = await fetch(
        `/api/v1/news/?sort=-date&limit=${newsNumber}&page=${nextPage}`
      );
      const { data } = await json.json();

      if (data.length === 0) this.nomoreNewsLeft = true;
      data.forEach(el => this.news.push(el));
    }
  }
};
</script>
<style scoped>
.wrap-box {
  max-width: 1250px;
  width: calc(100% - 30px);
  margin: 0 auto 30px auto;
  padding: 1px 0;
}

h3.heading {
  color: #4e5154;
  margin: 30px auto 30px 40px;
  font-size: 34px;
  text-align: left;
}

h3.heading::after {
  content: " ";
  display: block;
  width: 100%;
  max-width: 200px;
  height: 3px;
  top: 100%;
  left: 0;
  background: #aec3de;
}

.wrap-box p {
  font-size: 22px;
  font-weight: 700;
  text-align: center;
  height: 30px;
  margin: 10px auto 15vh auto;
}

.news-block {
  width: 100vw;
  height: auto;
  margin-bottom: 12vh;
}

.news-block .heading {
  font-size: 34px;
  color: #222529;
  opacity: 0.8;
  margin-bottom: 12px;
}

.news-block .subheading {
  display: block;
  margin-bottom: 50px;
  color: #51b2df;
  text-decoration: none;
  font-size: 18px;
}

.news-container {
  max-width: 1250px;
  margin: 0 auto;
  height: auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
}

.news-item {
  display: block;
  text-transform: none;
  color: inherit;
  cursor: pointer;
  min-width: 30%;
  width: 300px;
  height: 100%;
  text-align: left;
  margin: 20px auto;
}

.news-item:hover .news-item-image {
  filter: grayscale(70%);
  box-shadow: 3px 5px 8px 0 rgba(0, 0, 0, 0.7),
    6px 9px 18px 0 rgba(0, 0, 0, 0.4), 10px 15px 26px 0 rgba(0, 0, 0, 0.25);
}

.news-item:hover .news-item-date {
  color: #293863e5;
  opacity: 1;
}

.news-item-image {
  width: 100%;
  height: 190px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 6px;
  box-shadow: 3px 5px 8px 0 rgba(0, 0, 0, 0.35),
    5px 8px 15px 0 rgba(0, 0, 0, 0.2);
  transition: all 0.3s;
}

.news-item-title {
  display: block;
  font-size: 20px;
  color: #333;
  margin: 25px 0 12px 0;
  font-weight: 500;
}

.news-item-date {
  color: #3192bf;
  font-size: 17px;
  opacity: 0.85;
}
</style>